window.TabbedContentContainer = (initial_tab) => {
	return {
		active_tab: initial_tab,
		active_tab_index: 0,
		sliderBox: null,
		init(){
			this.sliderBox = this.$refs.slideBox;
		},
		slideOut() {
			this.sliderBox.style.transform = 'translateX(-100vw)';
		},
		slideIn() {
			this.sliderBox.style.transform = 'translateX(0)';
		},
		activateSlider(tab_title, tab_index) {
			// sliderbox moves out
			this.slideOut();
			setTimeout(() => {
				// change information on the tab while its off screen
				this.active_tab = tab_title;
				this.active_tab_index = tab_index;
				// sliderbox moves back in
				this.slideIn();
			}, 500);
		}
	};
};
